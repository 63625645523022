.footer {
  background: rgb(51 51 51);
  padding: 10px;
}

.foot_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
}


.flt {
  float: left;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

.videoupl {
  box-shadow: 0 0px 4px rgb(105 105 105 / 50%);
  background-color: rgba(245, 245, 245, 1);
  padding: 20px;
  /* margin-bottom: 25px; */
  width: 55% !important;
  margin: 30px 0px 0px 55px;
}


.page_container {
  padding: 40px 20px;
  min-height: calc(100vh - 117px);
}



.header_menu {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}



.page_head {
  font-size: 26px;
  font-weight: 500 !important;
  color: rgb(90, 0, 68);
  text-transform: uppercase;
}

.Card-container {

  text-align: center;
}

.card h2 {
  color: #7a447a;
  padding: 20px;
}



.card {
  box-shadow: 0 4px 8px 0 rgb(139 22 150 / 20%);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.Video {
  padding: 40px;
}

.video-head h2 {
  color: rebeccapurple;
}

.back_button {
  text-align: left !important;
  /* text-align: right; */
}

.back_button button {
  text-decoration: none;
  padding: 6px 30px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}


.With-head {
  padding: 10px;
  text-align: left;
  color: rgb(100 43 115);
}

.videoupl {
  text-align: left;
  padding: 30px 8px;
}

.Video video {
  width: 100%;
}

.videoupl input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
}