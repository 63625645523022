.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}


.page-container{
align-items: center;}

.Card-container{
    
    text-align: center;
}

.card h2 {
    color: #7a447a;
    padding: 20px;
}

.card {
    box-shadow: 0 4px 8px 0 rgb(139 22 150 / 20%);
    transition: 0.3s;
    padding: 0px 30px;
}
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */

.card ul{
    list-style-type: none;
    text-align: left;
}
  .card li
  {
    text-decoration: none;
    padding: 5px;
    color: rgb(100 43 115);
    
  }

  .back_button {
    margin-top: 10px;
    text-align: right;
}
.back_button a {
    text-decoration: none;
    padding: 5px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.user_list img{
  width: 60px;
  height: 60px;
}
.user_list {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  text-align: left;
}


.page_head {
  text-align: left;
  color: rgb(100 43 115);
  padding: 10px;
}

.styldetails3
{


  font-size: 14px;
    /* line-height: 27px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 31px;
    color: #000000;
    text-align: left;
    list-style-type: none;
}