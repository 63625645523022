@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap');

body {
    font-family: 'Zen Kaku Gothic New', sans-serif !important;
}

.flt {
    float: left;
    width: 100%;
}

ul {
    margin: 0px;
    padding: 0px;
}

.header_container {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
    border-bottom: 1px solid #642B73;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo {
    width: 10%;
}
.menuH {
    padding: 0px !important;
    margin: 0px !important;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menuH li {
    list-style-type: none;
    display: inline-block;
    padding: 0px 15px;
    position: relative;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.menuH li a {
    font-size: 12px !important;
    font-weight: 600;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.2s all ease-in-out;
}
.menuH li .dropdown li {
    float: left;
    width: 100%;
}
.menuH li .dropdown li a {
    float: left;
    width: 100%;
    padding: 10px 0px;
}
.menuH li .dropdown {
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 30px;
    left: -32px;
    width: 170px;
    background-color: rgb(255 255 255);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
    text-align: left;
    border-radius: 3px;
    border-bottom: 2px solid rgb(90, 0, 68);
    transform: translateY(70%);
    transition: 0.5s all ease-in-out;
    opacity: 0;
    visibility: hidden;
}
.menuH li:hover .dropdown {
    transform: translateY(0%);
    transition: 0.5s all ease-in-out;
    opacity: 1;
    visibility: visible;
    z-index: 9;
}
.header .row {
    align-items: center;
}

.header_text {
    text-align: center;
    padding: 10px 0px;
    text-transform: uppercase;
    color: #00415A;
}

.header_menu ul {
    list-style-type: none;
    display: flex;
}

.header {
    padding: 10px 20px;
    border-bottom: 2px solid #F1AAAA;
}

.header_menu ul li {

    text-decoration: none;
}

.logout{
    text-decoration: none;
    border-radius: 4px;
    height: 30px;
    width: 31px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}