/* .login_page {
  background: linear-gradient(to bottom, #003466 50%, #003f7d 50%);
  height: 100vh;
  position: relative;
}
.login_box {
  margin: 0% auto;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 10px rgb(32 32 32);
  width: 75%;
  border-radius: 10px;
}
.login_head {
  font-size: 33.81px;
  font-weight: 500;
  color: #003f7d;
  margin-bottom: 40px;
  font-family: "Montserrat";
  font-style: normal;
  line-height: 41px;
  padding-left:5px;
}

.user_id {
  float: left;
  font-family: "Montserrat";
  font-size: 17px;
  color: #012348;
  font-weight: 500;
  padding-bottom: 15px;
}
.password {
  float: left;
  font-family: "Montserrat";
  font-size: 17px;
  color: #012348;
  font-weight: 500;
  padding-bottom: 15px;
}

.login_box .form-group {
  margin-bottom: 20px;
  position: relative;
}

.login_box .form-group::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 70%;
  z-index: 9;
  transform: translateY(-50%);
  font-size: 18px;
  color: #ff8e01;
}

.login_box .form-group:first-child::before {
  content: "\f007";
}
.login_box .form-group:last-child::before {
  content: "\f084";
}
.login_box input {
  padding: 12px 10px 12px 50px;
  border-bottom: 1px solid #dddddd;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  width: 100%;
  margin-bottom: 3px;
   font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;


}
.login_btn {
  margin: 25px 0px 10px 0px;
}

.login_btn {
  text-align: right;
}
.login_btn button {
  font-size: 15px;
  background: #003466;
  color: rgb(255, 255, 255) !important;
  border: none;
  text-transform: uppercase;
  padding: 15px 50px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.version {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13.5225px;
  color: #003f7d;
  float: left;
}

.login_left {
  box-shadow: 0px 0px 20px rgba(0, 12, 116, 0.2);
  border-radius: 10px 0px 0px 10px;
  margin: 5px 3px;
  padding: 25px 0px;
}
.login_left img {
  width: 100%;
}

.login_right {
  padding: 4% 8%;
}

.login_box .row {
  align-items: center;
}

.required {
  padding: 5px 0px;
}
/* .fa-play:before {
  content: "\F04B";
  padding-left: 17px;
  background-color: #003F7D;
} */

/* @media (max-width: 767px) {
  .login_btn {
    text-align: center;
    margin: 20px 0px;
  }
  .login_page {
    background: linear-gradient(to bottom, #003466 50%, #003f7d 50%);
    height: 100vh;
    position: relative;
    padding: 20px;
    overflow: auto;
  }
  .login_box {
    width: 90%;
  }
  .login_head {
    font-size: 30px;
    text-align: center;
  }
  .mobile_logo {
    width: auto !important;
  }
  .login_left {
    box-shadow: none;
    border-radius: 0px;
    margin: 0px;
    padding: 30px 0px 10px 0px;
    text-align: center;
  }
} */
 


@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap');

body{
    font-family: 'Zen Kaku Gothic New', sans-serif !important;
}

.flt{
    float: left;
    width: 100%;
}

ul {
    margin: 0px;
    padding: 0px;
}

.logo img{
    width: 55px;
}
.login_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.login_box {

  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
  padding: 55px;
  float: left;
  width: 500px;
  border-bottom: 3px solid rgb(90, 0, 68);

}
.login_logo {
  text-align: center;
}
.login_head {
  padding: 20px;
  text-align: center;
}
.login_box label {
  color: rgba(102, 102, 102, 1);
  float: left;
  width: 100%;
  font-size: 14px;
}
.login_box input {
  color: rgb(0,0,0);
  font-size: 15px;
  height: 45px;
  background-color: rgb(245 245 245);
}


.login_head {
  padding: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #642B73;
  text-transform: uppercase;
}


.btn button {
  background: linear-gradient(268.13deg, #642B73 8.91%, #C6426E 51.58%);
  border-radius: 4px;
  padding: 8px 45px;
  color: #FFFFFF;
  border: none;
  font-size: 15px;
}



 