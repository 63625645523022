.flt{
    float:left;
    width:100%;
}

.page_container{
    padding:40px 20px;
    min-height: calc(100vh - 117px);
}

.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}