
.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}



.Dashboard{
    padding: 20px 0px;
}

.dashboard_tab {
    justify-content: space-between;
}

.dashboard_tab li {
    width: calc(25% - 15px);
    margin-bottom: 30px;
}
.dashbox {
    border-radius: 0.25rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    cursor: pointer;
}


.dash-1 {
    background-color: rgb(23 162 184);
}

.dash-2{

    background-color: rgb(40 167 69);
}


.dash-3{
    background-color: rgb(255 193 7);
}


.dash-top{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.dash-left {
    color: rgb(255, 255, 255);
}

.dash-right {
    text-align: center;
    color: rgba(0, 0, 0, .15);
    font-size: 70px;
}




.display-date ul{
    display: flex;
    list-style-type: none;
    justify-content: space-between;
}


.dash-count{
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: 'Teko', sans-serif;
    line-height: 45px;
}


.dash-users{
    font-size: 20px;
    line-height: 18px;

}

.dash-right{
    
        text-align: center;
        color: rgba(0, 0, 0, .15);
        font-size: 70px;
    
}

.dash-right i {
    transition: 0.5s all ease-in-out;
}


.dashbox.pink {
    background:#dc3545;
   
}
.dashbox.green {
    background: #17a2b8;
}

.dashbox.blue {
    background:  #28a745;
}

.dashbox.yellow{
    background: #ffc107;
}

.dashbox.yellow .dash_icon span {
    color:#ffc107;
}

.dashbox {
    color: #fff;
    border-radius: 8px;
    padding: 25px 11px;
    text-align: center;
    box-shadow: 0 0 10px rgb(85 85 85 / 60%);
    display: flex;
    align-items: center;
}

.dash_icon {
    width: 70px;
    margin-right: 15px;
}


.dashbox.pink .dash_icon span {
    color: rgb(225 53 69);
}


.dashbox.green .dash_icon span {
    color: rgb(0 163 183);
}

.dashbox.blue .dash_icon span {
    color: rgb(20 165 78);
}
.dash_icon span {
    background-color: rgb(255 255 255);
    height: 75px;
    width: 75px;
    display: inline-block;
    line-height: 78px;
    border-radius: 50%;
    font-size: 30px;
}

.dashbox_cont {
    width: calc(100% - 85px);
    text-align: left;
}

.dashtag {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    border-bottom: 1px solid #fff;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.dashtag1{
    color:  rgb(17, 12, 12);
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    border-bottom: 1px solid rgb(17, 12, 12);
    margin-bottom: 15px;
    padding-bottom: 15px;

}

.dash_number1{
    color:  rgb(17, 12, 12);
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
}

.dash_number {
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
}

button.btn2 {
    text-decoration: none;
    padding: 6px 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    /* background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%); */
    background: rgb(100 43 115);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
    margin-left: 4px;
}


button.btn1 {
    text-decoration: none;
    padding: 6px 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    /* background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%); */
    background: rgb(100 43 115);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
   
}

.page_head span{
    font-size: 1px;
    font-size: 13px;
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    padding: 5px 5px;
    border-radius: 5px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin-left: 13px;
}
.showrec{
    align-items: center;
}

.DatePick span{
    /* background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%); */
    color: rgb(90, 0, 68);
    margin-right: 5px;
    /* border: 1px solid ; */
    border-radius: 5px;
}

/* .DatePick{
    border: 1px solid rgb(100 43 115);
} */
.DatePick {
    margin-bottom: 3px;
}