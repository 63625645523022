@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap');

body {
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
}

.alert {
  position: fixed !important;
  bottom: 10px;
  float: right;
  right: 10px;
  box-shadow: 0px 0px 8px 0px;
  z-index: 9;
}

.alert-danger {
  z-index: 100000000000 !important;
}

.pagination_container {
  margin-top: 20px;
}

.pagination {
  justify-content: flex-end;
}

.pagination li {
  margin-left: 4px;
  padding: 4px 0px;
}

.pagination li a {
  text-decoration: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
}

.pagination li:first-child a,
.pagination li:last-child a {
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}

.pagination li:nth-child(2) a,
.pagination li:nth-last-child(2) a {
  background-color: rgb(100 43 115);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}


.invalid-feedback {
  display: block !important;
}


.load {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
}

.load img {
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 60px);
  animation: zoom-in-zoom-out 1s ease infinite;
}


.react-confirm-alert-body {
  /* font-family: Arial, Helvetica, sans-serif; */
  width: 400px;
  padding: 30px;
  text-align: left;
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%) !important;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
  color: #ffffffe0 !important;
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #2b00806b !important;
  border: none;
  display: inline-block;
  padding: 5px 18px !important;
  color:#ffff !important;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 18px !important;
  cursor: pointer;
}
.react-confirm-alert-button-group button {
  background-color: #f8f9fa !important;
  color: #662f71 !important;
}
@keyframes zoom-in-zoom-out {
  0% {
      transform: scale(1, 1);
  }

  50% {
      transform: scale(0.8, 0.8);
  }

  100% {
      transform: scale(1, 1);
  }
}