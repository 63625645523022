
.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}


.fashion_submit button {
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
    padding: 5px 30px;
    border-radius: 4px;
    font-weight: 600;
    margin-right: 15px;
}

.table_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
    border-radius: 4px;
}