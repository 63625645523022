.flt{
  float:left;
  width:100%;
}

.user_list img {
width: 60px;
height: 60px;
}
.user_list {
box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
border-radius: 4px;
padding: 15px;
}
.user_list li {
list-style-type: none;

color: rgb(0,0,0);

margin-bottom: 28px;
}



.user_list li b {
float: left;
font-size: 16px;

}

.news_image {
box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
text-align: center;
padding: 20px;
border-radius: 4px;
}
.news_image img {
width: 250px;
height: 250px;
border-radius: 0%;
border: 1px solid rgb(201 201 201);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; 
  border-top: 10px solid #383636; 
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}  

.likes i {
  color: purple;
}


.styuser_list11{

  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
  margin-top: 20px;


}

.titledesc{
  font-size: 14px;
  line-height: 28px;
}


.titledesc b{

  font-weight: 400;
  font-size: 20px;
  color: #92278F;
}

/* .styuser_list b{
  font-weight: 400;
font-size: 20px;
line-height: 31px;
color:  rgb(100 43 115);
}

.styuser_list span{
font-size: 14px;
}

.styuser_list11 span{
font-size: 14px;
}
.styuser_list11 b{
  font-weight: 400;
font-size: 20px;
line-height: 31px;
color:  rgb(100 43 115);
} */