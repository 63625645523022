.flt {
  float: left;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

.back_button {
  margin-top: 20px;
  text-align: right;
}

.back_button a {
  text-decoration: none;
  padding: 6px 30px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}

.user_list img {
  width: 60px;
  height: 60px;
}
.user_list {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
}
.user_list li {
  list-style-type: none;
  width: 100%;
  color: rgb(0,0,0);
  float: left;
  margin-bottom: 15px;
}

.user_list li b {
  width: 35%;
}
.viewfash_profile {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  text-align: center;
  padding: 20px;
  border-radius: 4px;
}
.viewfash_profile img {
 
  border-radius: 1px !important;
  border:0;
}

.styhead2{
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color:rgb(100 43 115);
  text-transform: uppercase;
}

.styuser_list2{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}

.styuser_list2 img{
  width:20%;
  height: 20%;
}

.fash_img img {
  max-width: 100%;
  /* height: 114px; */
}
.fashion_view_list {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 10px;
}