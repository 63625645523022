.flt {
    float: left;
    width: 100%;
  }
  
  ul {
    margin: 0px;
    padding: 0px;
  }
  
  .back_button {
    margin-top: 20px;
    text-align: right;
  }
  
  .back_button a {
    text-decoration: none;
    padding: 6px 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
  }
  
  .buser_list img {
    width: 60px;
    height: 60px;
  }
  .buser_list {
    box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
    border-radius: 4px;
    padding: 15px;
  }
  .buser_list li {
    list-style-type: none;
    color: rgb(0,0,0);
    margin-bottom: 15px;
    line-height: 35px;
  }
  
  .viewuser_profile {
    box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
    text-align: center;
    padding: 20px;
    border-radius: 4px;
  }
  .viewuser_profile img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 1px solid rgb(201 201 201);
  }

  .blogimg img {
    width: 100%;
    height: 50%;
}


.back_button1 i {
  font-size: 17px;
}

.styuser_listbl1{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}

.styuser_listbl1{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}

.styuser_listbl2{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
  margin-top: 15px;
}
.styuser_listbl3{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}
.styuser_listbl4{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}

.styuser_listbl5{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
  margin-top: 15px;
}


.titledesc b{

  font-weight: 400;
  font-size: 20px;
  color: #92278F;
}
